import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { dataportfolio, meta } from "../../content_option";
import { Parallax } from 'react-parallax';
import { StickyContainer, Sticky } from 'react-sticky';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

export const Portfolio = () => {

	const UnityMobile = () => {
		return (
			<>
            
	          <Row className="mb-5 mt-3">
	              <div className="portfolio-title">
	                  <div className="display-8 mb-8">
	                    <h1 className="portfolio-project-title"><img className="logo-icon" src={process.env.PUBLIC_URL + "/MadameMisterioLogo.png" }/>Madame Misterio</h1>
	                  </div>
	              </div>
	          </Row>
			  
			  
	          <Row className="portfolio-section">
	            <Col lg="5">
				<center>
				  <img style={{width: "280px"}} src={process.env.PUBLIC_URL + "/MadameMisterioPoster.jpg"}/>
				  </center>
	            </Col>
	            <Col lg="7" className="d-flex align-items-top">
	              <Row >
	                  <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
	                    <p style={{fontSize: "1.2rem" }}>Entretainment App developed in Unity for iOS and Android</p>
	                    <p style={{fontSize: "1.2rem" }}>In this tarot app, you pick 3 cards, write your question and the AI will come up with an analysis for you</p>
	                    <p style={{fontSize: "1.2rem" }}>Features:</p>
	                    <ul style={{paddingTop: "10px", listStyle: "yes", listStyleType: "circle"}}>
	                        <li><p>In-app purchase</p></li>
							<li><p>Firebase Authentication</p></li>
	                    </ul>

	                    <div>
	                      <ul className="list-container">
	                          <li className="feature-button">Unity</li>
							  <li className="feature-button">ChatGPT</li>
							  <li className="feature-button">Firebase</li>
	                      </ul>
	                    </div>
	                    <center>
	                      <div id="button_h" className="ac_btn btn">
	                            <a href="https://onelink.to/kwjw48">Download</a>

	                              <div className="ring one"></div>
	                              <div className="ring two"></div>
	                              <div className="ring three"></div>
	                        </div>
            
            
	                    </center>
	                  </div>
	              </Row>
	            </Col>
	          </Row>
            			  
			  
			  <Row className="mb-5 mt-3">
	              <div className="portfolio-title">
	                  <div className="display-8 mb-8">
	                    <h1 className="portfolio-project-title"><img className="logo-icon" src={process.env.PUBLIC_URL + "/ZiggyLogo.png" }/>Ziggy Cliff </h1>
	                  </div>
	              </div>
	          </Row>
			  

	          <Row className="portfolio-section">
	            <Col lg="5">
	              <video
	                  className="portfolio-video-portrait"
	                  loop
	                  autoPlay
	                  muted
	                  playsInline
										controls
									  poster={process.env.PUBLIC_URL + "/ZiggyPoster.jpg"}>
	                  <source src={process.env.PUBLIC_URL + "/ZiggyCliff.mp4"} type="video/mp4"/>
	              </video>

	            </Col>
	            <Col lg="7" className="d-flex align-items-top">
	              <Row >
	                  <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
	                    <p style={{fontSize: "1.2rem" }}>Game developed in Unity for iOS and Android</p>
	                    <p style={{fontSize: "1.2rem" }}>This game was designed and developed entirely by me, using free assets from Unity Asset Stores 3D</p>
	                    <p style={{fontSize: "1.2rem" }}>This is an endless runner game, where you control a car by touching up and down buttons. You must keep the car on track, avoiding the cliff and the other cars on the opposite direction. The difficulty level increases as you keep playing.</p>
	                    <p style={{fontSize: "1.2rem" }}>Features:</p>
	                    <ul style={{paddingTop: "10px", listStyle: "yes", listStyleType: "circle"}}>
	                        <li> <p>You can choose among 6 different cars, varying in size, speed, handling and number of continues</p></li>
	                        <li> <p>Rankings</p></li>
	                        <li> <p>Achievements</p></li>
	                    </ul>

	                    <div>
	                      <ul className="list-container">
	                          <li className="feature-button">Unity</li>
	                      </ul>
	                    </div>
	                    <center>
	                      <div id="button_h" className="ac_btn btn">
	                            <a href="https://onelink.to/kwjw48">Download</a>

	                              <div className="ring one"></div>
	                              <div className="ring two"></div>
	                              <div className="ring three"></div>
	                        </div>
            
            
	                    </center>
	                  </div>
	              </Row>
	            </Col>
	          </Row>
            
            
            
	          <Row className="mb-5 mt-3">
	              <div className="portfolio-title">
	                  <div className="display-8 mb-8">
	                    <h1 className="portfolio-project-title"><img className="logo-icon" src={process.env.PUBLIC_URL + "/BozonaroLogo.png" }/>Bozonaro </h1>
	                  </div>
	              </div>
	          </Row>

	          <Row className="portfolio-section">
	            <Col lg="5">
	              <video
	                  className="portfolio-video-portrait"
	                  loop
	                  autoPlay
	                  muted
	                  playsInline
										controls
									  poster={process.env.PUBLIC_URL + "/BozonaroPoster.jpg"}>
	                  <source src={process.env.PUBLIC_URL + "/bozonaro_v3.mp4"} type="video/mp4"/>
	              </video>

	            </Col>
	            <Col lg="7" className="d-flex align-items-top">
	              <Row >
	                  <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
	                    <p style={{fontSize: "1.2rem" }}>Game developed in Unity for iOS and Android</p>
	                    <p style={{fontSize: "1.2rem" }}>This game was designed and developed entirely by me, including the 3D character and UI elements</p>
	                    <p style={{fontSize: "1.2rem" }}>The game has two modes: Avatar mode, where the player interacts with a 3D version of Brazil's president, like a political satirical version of Talking Tom. In this mode, the user can also project the 3D version on Augmented Reality.</p>
	                    <p style={{fontSize: "1.2rem" }}>The other mode is a running game, called "Run, Bozo",  inspired on Subway Surfers, you move the president while he runs away from the ghost of Communism	, trying to avoid cars and collecting oranges along the way</p>
	                    <p style={{fontSize: "1.2rem" }}>The game has level-based mechanics where features are unlocked as the player progresses. The progression comes as the player watches ads and spends time in the game </p>
	                    <p style={{fontSize: "1.2rem" }}>Features:</p>
	                    <ul style={{paddingTop: "10px", listStyle: "yes", listStyleType: "circle"}}>
	                        <li> <p>Play the mini-game "Run, Bozo", competing against other players and collecting oranges that can be exchanged by prizes</p></li>
	                        <li> <p>Slap Bozonaro and hear him complain</p></li>
	                        <li> <p>Fight him on a one-on-one boxing match</p></li>
	                        <li><p>Changes the skin and add accessories like masks, presidential sash, wigs, etc</p></li>
	                        <li> <p>Use Bozonaro head as a mask using the front camera</p></li>
	                    </ul>

	                    <p style={{fontSize: "1.2rem" }}>Interesting facts:</p>
	                    <ul style={{paddingTop: "10px", listStyle: "yes", listStyleType: "circle"}}>
	                        <li> <p>Over 200k downloads on iOS and Android combined</p></li>
	                        <li> <p>It was mentioned twice on different newspapers as a case of "games in politics"</p></li>
	                        <li> <p>4.4 stars on iOS with nearly 500 reviews</p></li>
	                    </ul>
	                    <div>
	                      <ul className="list-container">
	                          <li className="feature-button">Unity</li>
	                          <li className="feature-button">Firebase</li>
	                          <li className="feature-button">ARKit</li>
	                          <li className="feature-button">ARCore</li>
	                      </ul>
	                    </div>
	                    <center>
	                      <div id="button_h" className="ac_btn btn">
	                            <a href="https://tinyurl.com/Bozonaro/">Download</a>

	                              <div className="ring one"></div>
	                              <div className="ring two"></div>
	                              <div className="ring three"></div>
	                        </div>
	                    </center>
	                  </div>
	              </Row>
	            </Col>
	          </Row>

		      <Row className="mb-5 mt-3">
		          <div className="portfolio-title">
		              <div className="display-8 mb-8">
		                <h1 className="portfolio-project-title"><img className="logo-icon" src={process.env.PUBLIC_URL + "/AchromaLogo.png" }/>Achroma </h1>
		              </div>
		          </div>
		      </Row>


			 <Row className="portfolio-section">

			   <Col lg="5">
			     <video
			         className="portfolio-video-portrait"
			         loop
			         autoPlay
			         muted
			         playsInline
							 controls
							 poster={process.env.PUBLIC_URL + "/AchromaPoster.jpg"}>
			         <source src={process.env.PUBLIC_URL + "/achroma.mp4"} type="video/mp4"/>
			     </video>

			   </Col>
			   <Col lg="7" className="d-flex align-items-top">
			     <Row >
			         <div style={{marginTop: "20px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
			           <p style={{fontSize: "1.2rem" }}>Game developed in Unity for iOS and Android. This game is based on the Achroma card game by Real Runner Studios.</p>
			           <p style={{fontSize: "1.2rem" }}>My role in this project is anything related to development and most of the UX and overall game design.</p>
			           <p style={{fontSize: "1.2rem" }}>Features:</p>
			           <ul style={{paddingTop: "10px", listStyle: "yes", listStyleType: "circle"}}>
			               <li> <p>Companion mode with multiplayer mode: players connect to each other to control their balances</p></li>
			               <li> <p>Story mode: The player engage in a narrative-based game where different choices define your destiny</p></li>
			               <li><p>Card database: The player can register their physical cards in the game and unlock the related avatars and use them throughout the game</p></li>
			               <li> <p>AR mode: The player can trigger AR experiences when pointing at their physical cards</p></li>
			           </ul>
			           <p style={{fontSize: "1.2rem" }}>For this game I built a node-based story engine where the story can be designed from an editor and the output file is downloaded by the game. The editor can be found <a style={{fontSize: "1.2rem", backgroundColor: "blue"}} href="https://diegomeire.github.io/ShardsOfAchromaEditor">here</a> (Desktop only) </p>
			           <p style={{fontSize: "1.2rem" }}>This game uses Firebase to authenticate and read data for each individual user. Everything is stored in Firebase Realtime database.</p>
			           <div>
			           <ul className="list-container">
			               <li className="feature-button">Unity</li>
			               <li className="feature-button">Firebase</li>
			               <li className="feature-button">ARKit</li>
			               <li className="feature-button">ARCore</li>
			           </ul>
			           </div>
			           <center>
			             <div id="button_h" className="ac_btn btn">
			                   <a href="https://l.linklyhq.com/l/fRxe/">Download</a>

			                     <div className="ring one"></div>
			                     <div className="ring two"></div>
			                     <div className="ring three"></div>
			               </div>
			           </center>
			         </div>
			     </Row>
			   </Col>
			</Row>


			</>
		)
	}

	const UnityWebGL = () => {
		return (
			<>

			<Row className="mb-5 mt-3">
			    <div className="portfolio-title">
			        <div className="display-8 mb-8">
			          <h1 className="portfolio-project-title">Zig Zag & Marbles </h1>
			        </div>
			    </div>
			</Row>
			<Row className="portfolio-section">
			  <Col lg="7">
			    <img  className="po_item" src={process.env.PUBLIC_URL + "/ZigZagScreenshot.png"}/>
			  </Col>
			  <Col lg="5" className="d-flex align-items-top">
			    <Row >
			        <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
			          <p style={{fontSize: "1.2rem" }}>A ZigZag game where you can pick a marble among different options of size and handling and your mission is to keep the marble on the path without losing it or having it destroyed on obstacles. This game was inspired on Ketchapp's ZigZag game and it served as a proof of concept of a game I'm building in the future   </p>
			          <div>
			            <ul className="list-container">
			                <li className="feature-button">Unity</li>
			            </ul>
			          </div>
			          <center>
			            <div id="button_h" className="ac_btn btn">
			                  <a href="https://diegomeire.github.io/ZigZagMarbles/">Play Game</a>
			                    <div className="ring one"></div>
			                    <div className="ring two"></div>
			                    <div className="ring three"></div>
			              </div>
			          </center>
			        </div>
			    </Row>
			  </Col>
			</Row>


			<Row className="mb-5 mt-3">
			    <div className="portfolio-title">
			        <div className="display-8 mb-8">
			          <h1 className="portfolio-project-title">Rocket Science </h1>
			        </div>
			    </div>
			</Row>
			<Row className="portfolio-section">
			  <Col lg="7">
			    <img  className="po_item" src={process.env.PUBLIC_URL + "/RocketScienceScreenshot.png"}/>
			  </Col>
			  <Col lg="5" className="d-flex align-items-top">
			    <Row >
			        <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
			          <p style={{fontSize: "1.2rem" }}>Small game created for an arcade experience for Lush. Some of the assets I built using Blender, like the spaceship. The game was deployd on an arcade machine </p>
			          <div>
			            <ul className="list-container">
			                <li className="feature-button">Unity</li>
			            </ul>
			          </div>
			          <center>
			            <div id="button_h" className="ac_btn btn">
			                  <a href="https://diegomeire.github.io/Experiments/RocketScienceWebGL/">Play Game</a>

			                    <div className="ring one"></div>
			                    <div className="ring two"></div>
			                    <div className="ring three"></div>
			              </div>
			          </center>
			        </div>
			    </Row>
			  </Col>
			</Row>
			</>
		)
	}


	const UnityVR = () => {
		return (
			<>

				<Row className="mb-5 mt-3">
				    <div className="portfolio-title">
				        <div className="display-8 mb-8">
				          <h1 className="portfolio-project-title">VR Meditation app </h1>
				        </div>
				    </div>
				</Row>
				<Row className="portfolio-section">
				  <Col lg="5" className="d-flex align-items-top">
					<video
				        className="portfolio-video-square-reduced"
				        loop
				        autoPlay
				        muted
				        playsInline
								controls
							  poster={process.env.PUBLIC_URL + "/LushVRPoster.jpg"}
								>
				        <source src={process.env.PUBLIC_URL + "/LushVR_Forest_v2.mp4"} type="video/mp4"/>
				    </video>

					</Col>
				  <Col lg="7" className="d-flex align-items-top">
				    <Row >
				        <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
				          <p style={{fontSize: "1.2rem" }}>Oculus Quest 2 meditation app developed for Lush for sound immersion. The sound affects particles around the user to provoke relaxation. This app is currently on Alpha channel to be used only by the company's staff on Spa sessions.</p>
				          <div>
				            <ul className="list-container">
				                <li className="feature-button">Unity</li>
				            </ul>
				          </div>
				        </div>
				    </Row>
				  </Col>
				</Row>

				<Row className="mb-5 mt-3">
				    <div className="portfolio-title">
				        <div className="display-8 mb-8">
				          <h1 className="portfolio-project-title">VR Color Treatment </h1>
				        </div>
				    </div>
				</Row>
				<Row className="portfolio-section">
				  <Col lg="5" className="d-flex align-items-top">
						<video
					        className="portfolio-video-square-reduced"
					        loop
					        autoPlay
					        muted
					        playsInline
									controls
								  poster={process.env.PUBLIC_URL + "/ColorWashPoster.jpg"}>
					        <source src={process.env.PUBLIC_URL + "/LushVR_Color.mp4"} type="video/mp4"/>
					    </video>
					</Col>
				  <Col lg="7" className="d-flex align-items-top">
				    <Row >
				        <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
				          <p style={{fontSize: "1.2rem" }}>Oculus Quest 2 color treatment app developed for Lush for sound and color immersion. The shapes and the colors are affected by the sound using FFT analysis. This app is currently on Alpha channel to be used only by the company's staff on Spa sessions.</p>
				          <div>
				            <ul className="list-container">
				                <li className="feature-button">Unity</li>
				            </ul>
				          </div>
				        </div>
				    </Row>
				  </Col>
				</Row>

			</>
		)
	}



		const WebAR = () => {
			return (
				<>

					<Row className="mb-5 mt-3">
					    <div className="portfolio-title">
					        <div className="display-8 mb-8">
					          <h1 className="portfolio-project-title">Unicorn experience </h1>
					        </div>
					    </div>
					</Row>
					<Row className="portfolio-section">
					  <Col lg="5" className="d-flex align-items-top">
					  <video
					        className="portfolio-video-square"
					        loop
					        autoPlay
					        muted
									controls
					        playsInline>
					        <source src={process.env.PUBLIC_URL + "/unicorn.mp4"} type="video/mp4"/>
					    </video>
					  </Col>
					  <Col lg="7" className="d-flex align-items-top">
					    <Row >
					        <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
					          <p style={{fontSize: "1.2rem" }}>Small Babylon.js AR experience based on jeeliz face filter framework. Here I built the unicorn head in Blender and used as face filter using jeeliz face filter framework </p>
					          <div>
					            <ul className="list-container">
					                <li className="feature-button">Babylon.js</li>
					                <li className="feature-button">jeeliz</li>
					                <li className="feature-button">Blender</li>
					            </ul>
					          </div>
					          <center>
					            <div id="button_h" className="ac_btn btn">
					                  <a href="https://lush-unicorn.web.app/">Play it</a>

					                    <div className="ring one"></div>
					                    <div className="ring two"></div>
					                    <div className="ring three"></div>
					              </div>
					          </center>
					        </div>
					    </Row>
					  </Col>
					</Row>


				</>
			)
		}

	const AppleiOS = () => {
		return (
			<>
				<Row className="mb-5 mt-3">
				 <div className="portfolio-title">
				     <div className="display-8 mb-8">
				       <h1 className="portfolio-project-title"><img className="logo-icon" src={process.env.PUBLIC_URL + "/LushLabsLogo.png" }/>Lush Labs </h1>
				     </div>
				 </div>
				</Row>
				<Row className="portfolio-section">
				  <Col lg="5">
					<video
					    className="portfolio-video-portrait"
					    loop
					    autoPlay
					    muted
					    playsInline
							controls
							poster={process.env.PUBLIC_URL + "/LushLabsPoster.jpg"}>
					    <source src={process.env.PUBLIC_URL + "/lushlabs.mp4"} type="video/mp4"/>
					</video>
				  </Col>
				  <Col lg="7" className="d-flex align-items-top">
				    <Row >
				        <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
						  <p style={{fontSize: "1.2rem" }}>Lush Labs is an iOS and Android app designed to be a repository for different experiments with AR, Computer Vision and cool visual experiments </p>
						  <p style={{fontSize: "1.2rem" }}>For this app, I've designed multiple experiments. Most of them are not available anymore for download, but it's worth mentioning</p>
						  <p style={{fontSize: "1.2rem" }}>Main experiments:</p>
						  <ul style={{paddingTop: "10px", listStyle: "yes", listStyleType: "circle"}}>
						      <li><p>Moods: an iOS swift native experiment for meditation. It envolves fluid simulation with user interactions along proprietary music from Lush</p></li>
						      <li><p>Snow Fairy Cauldron: A Unity embedded experiment using a combination of 3D, fluid simulation and front facing camera</p></li>
						      <li><p>LushCam: an iOS swift native experiment version of Intagram filters. This is an experiment where the user can replace their head with fun representation of Lush products</p></li>
						      <li><p>AR Hunt: native iOS experiment that combines Geolocation, maps and AR. The user engages in a treasure hunt searching for places in an specific area. When they find the location, they can trigger an AR experience</p></li>

						  </ul>
								          <div>
								            <ul className="list-container">
								                <li className="feature-button">Unity</li>
								                <li className="feature-button">Swift</li>
								                <li className="feature-button">OpenCV</li>
								                <li className="feature-button">ARKit</li>
								                <li className="feature-button">ARCore</li>
								            </ul>
								          </div>


						  <div>
				            <ul className="list-container">
				                <li className="feature-button">Unity</li>
				            </ul>
				          </div>
				    <center>
				      <div id="button_h" className="ac_btn btn">
				            <a href="https://onelink.to/am5ngf">Download</a>

				              <div className="ring one"></div>
				              <div className="ring two"></div>
				              <div className="ring three"></div>
				        </div>
				    </center>
				        </div>
				    </Row>
				  </Col>
				</Row>
			</>
		)
	}



		const OldStuff = () => {
			return (
				<>
					<Row className="mb-5">
						<div className="portfolio-oldstuff-subtitle">
							<p>A list of old projects that are no longer available but were really important on my journey of learning app/game development</p>
						</div>
				</Row>

				<div style={{height:"100px"}}/>

				<Row className="mb-5 mt-3">
				 <div className="portfolio-title">
						 <div className="display-8 mb-8">
							 <h1 className="portfolio-project-title">Asteroid Outrace</h1>
						 </div>
				 </div>
				</Row>
				<Row className="portfolio-section">
					<Col lg="7">
					<Row>
						<img style={{marginLeft: "75px", left: "50%", width:"50%"}} src={process.env.PUBLIC_URL + "/AsteroidOutrace_1.jpg"}/>
					</Row>
					<Row>
						<img style={{marginLeft: "75px", left: "50%", width:"50%"}} src={process.env.PUBLIC_URL + "/AsteroidOutrace_2.jpg"}/>
					</Row>
					</Col>
					<Col lg="5" className="d-flex align-items-top">
						<Row >
								<div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
								<p style={{fontSize: "1.2rem" }}>A 3D endless space shooting game where the player control a spaceship dodging asteroids and going through checkpoints </p>
								<p style={{fontSize: "1.2rem" }}>Independent. Developed in 2015</p>
								<div>
									<ul className="list-container">
											<li className="feature-button">Unity</li>
									</ul>
								</div>


								</div>
						</Row>
					</Col>
				</Row>



					<Row className="mb-5 mt-3">
					 <div className="portfolio-title">
					     <div className="display-8 mb-8">
					       <h1 className="portfolio-project-title">Talking Fighter </h1>
					     </div>
					 </div>
					</Row>
					<Row className="portfolio-section">
					  <Col lg="7">
						<img style={{marginLeft: "75px", left: "50%", width:"50%"}} src={process.env.PUBLIC_URL + "/TalkingFighter_1.jpg"}/>
					  </Col>
					  <Col lg="5" className="d-flex align-items-top">
					    <Row >
					        <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
								  <p style={{fontSize: "1.2rem" }}>Talking Tom-like app where the user speaks, the "fighter" recognizes the speech and repeats it back with a distorted voice </p>
									<p style={{fontSize: "1.2rem" }}>The app also came with a fighting game, where the player fought the player</p>
									<p style={{fontSize: "1.2rem" }}>Independent. Developed in 2013</p>
				          <div>
				            <ul className="list-container">
				                <li className="feature-button">Objective C</li>
				                <li className="feature-button">Cocos2D</li>
				            </ul>
				          </div>


					        </div>
					    </Row>
					  </Col>
					</Row>


					<Row className="mb-5 mt-3">
					 <div className="portfolio-title">
					     <div className="display-8 mb-8">
					       <h1 className="portfolio-project-title">Celebrity Revenge 2</h1>
					     </div>
					 </div>
					</Row>
					<Row className="portfolio-section">
					  <Col lg="7">
						<Row>
							<img style={{marginLeft: "75px", left: "50%", width:"50%"}} src={process.env.PUBLIC_URL + "/CelebrityRevenge2_1.jpg"}/>
						</Row>
						<Row>
							<img style={{marginLeft: "75px", left: "50%", width:"50%"}} src={process.env.PUBLIC_URL + "/CelebrityRevenge2_2.jpg"}/>
						</Row>
					  </Col>
					  <Col lg="5" className="d-flex align-items-top">
					    <Row >
					        <div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
								  <p style={{fontSize: "1.2rem" }}>A 2D shooting game where the player shoots at nosy paparazzis using a paintball gun </p>
									<p style={{fontSize: "1.2rem" }}>Independent. Developed in 2013</p>
				          <div>
				            <ul className="list-container">
				                <li className="feature-button">Objective C</li>
				                <li className="feature-button">Cocos2D</li>
				            </ul>
				          </div>


					        </div>
					    </Row>
					  </Col>
					</Row>


					<Row className="mb-5 mt-3">
					 <div className="portfolio-title">
							 <div className="display-8 mb-8">
								 <h1 className="portfolio-project-title">Celebrity Revenge</h1>
							 </div>
					 </div>
					</Row>
					<Row className="portfolio-section">
						<Col lg="7">
						<Row>
							<img style={{marginLeft: "75px", left: "50%", width:"50%"}} src={process.env.PUBLIC_URL + "/CelebrityRevenge_2.jpg"}/>
						</Row>
						<Row>
							<img style={{marginLeft: "75px", left: "50%", width:"50%"}} src={process.env.PUBLIC_URL + "/CelebrityRevenge_1.jpg"}/>
						</Row>
						</Col>
						<Col lg="5" className="d-flex align-items-top">
							<Row >
									<div style={{marginTop: "50px", marginBottom: "50px", marginLeft: "10px", marginRight: "10px"}}>
									<p style={{fontSize: "1.2rem" }}>My first mobile game. A 2D Shooting game where the player shoots at nosy paparazzis using a paintball gun </p>
									<p style={{fontSize: "1.2rem" }}>Independent. Developed in 2010</p>
									<div>
										<ul className="list-container">
												<li className="feature-button">Objective C</li>
												<li className="feature-button">Cocos2D</li>
										</ul>
									</div>


									</div>
							</Row>
						</Col>
					</Row>

				</>
			)
		}



  return (
    <HelmetProvider>
	
      <Container className="Portfolio-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Portfolio | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-8">Portfolio</h1>
          </Col>

        </Row>


        <Row className="mb-5 mt-3">
		  		<Tabs className="portfolio-tabs mb-5 mt-3"  defaultActiveKey="unityMobile" fill>
			  	 	<Tab className="portfolio-tab" eventKey="unityMobile" title="Mobile">
			  			<UnityMobile />
										  			<AppleiOS />
				 		</Tab>
			  	 	<Tab className="portfolio-tab" eventKey="unityWebGL" title="WebGL">
			  			<UnityWebGL />
				 		</Tab>
			  	  <Tab className="portfolio-tab" eventKey="unityVR" title="VR">
			  			<UnityVR />
				 		</Tab>
			  	  <Tab className="portfolio-tab" eventKey="webAR" title="Web AR">
			  			<WebAR />
				 		</Tab>
						<Tab className="portfolio-tab" eventKey="old" title="Old">
			  			<OldStuff />
				 		</Tab>

		  		</Tabs>
        </Row>

        <Row className="mb-5 mt-3"/>

      </Container>
    </HelmetProvider>
  );
};
