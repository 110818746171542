import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata, meta } from "../../content_option";
import { Link } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";
//

export const AI = () => {
    
	
	const iframe = '<iframe class="iframe" allowTransparency="true" allow="autoplay; encrypted-media" style="pointer-events: auto;"src="https://playcanv.as/e/p/QuZq0A4V/" frameborder="0"></iframe>'; 
	
	function Iframe(props) {
	  return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
	}
	
  return (
    <HelmetProvider>
		<Iframe iframe={iframe} />
		<section id="home" className="home" style={{"height": "100vh", "width": "100%"}}>
			<Helmet>
			  <meta charSet="utf-8" />
			  <title> {meta.title}</title>
			  <meta name="description" content={meta.description} />
			  <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
			</Helmet>
			<div style={{"height": "100%", "width": "100%", "backgroundColor": "transparent"}}> {/*className="intro_sec d-block d-lg-flex align-items-center "*/}
				<Container>
					<Row className="mb-5 mt-7">
					  <Col lg="8">
						<h1 className="display-8 mb-8">AI Chat</h1>
						<hr className="t_border my-8 ml-0 text-left" />
					  </Col>
					</Row>
				</Container>

			</div>
		</section>
    </HelmetProvider>
  );
};
