const logotext = "HOME";
const meta = {
    title: "Diego Meire",
    description: "I’m Diego Meire. Creative Technologist and Game Developer working from Brazil",
};

const introdata = {
    title: "Diego Meire",
    animated: {
		first: "Computer Scientist",
        second: "Game Developer",
        third: "Creative Technologist"
    },
    description: "3D, AR and Games",
    your_img_url: "/DiegoPoster.png",
};

const dataabout = {
    title: "about myself",
    aboutme: "A programmer since the age of 14, I first fell in love with game development in 1997 having my first contact with my first programming languages. Unfortunately, game development was a dream difficult to pursue back in the day and I transferred my programming skills to systems development when I started working professionally in 2002. From 2002 to 2018 I worked on everything related to Systems, having worked as developer, system analyst, system lead and IT Manager on different companies from small to large size companies. However my true passion was on creative development and on 2018, my career changed completely and I could turn my creative and game development hobby as a profession. Since then I've been working with what I really love related to 3D, AR, VR, Games and R&D with new tech." 
};
const worktimeline = [{
        jobtitle: "Creative Technologist",
        where: "Lush Comestics",
        date: "2018-",
		activities: [
			"Game Development with Unity", 
			"Development of AR apps with Unity, Native iOS and Android", 
			"Research and Development of AR and Computer Vision with iOS (ARKit, Vision), OpenCV and Android (ARCore)",
			"Development of 3D and AR applications for the web on Babylon.js",
			"3D rendering with Blender"
		]
    },
    {
        jobtitle: "IT Manager",
        where: "Lush Comestics",
        date: "2016-2018",
		activities: [
			"ERP Project Management",
			"IT Infrastructure Management",
			"IT Procurement and purchase of technology goods"
		]
    },
    {
        jobtitle: "System Lead Engineer",
        where: "Lush Comestics",
        date: "2015-2016",
		activities: [
			"System development", 
            "System Integration development", 
            "ERP Systems project management"
		]
    },
    {
        jobtitle: "System Analyst / Developer",
        where: "Lush Comestics",
        date: "2014-2015",
		activities: [
			"System development", 
            "System Integration development", 
		]
    },
    {
        jobtitle: "System Analyst / Developer",
        where: "Ticket Serviços",
        date: "2007-2014",
		activities: [
			"24x7 systems support on card business and electronic payment systems",
			"Systems maintenance: Incident, Problem and Change Management",
			"Database developmennt with Oracle and SQL Server",
			"System development with Oracle and Delphi"
		]
    },
    {
        jobtitle: "Software Developer",
        where: "Bysoft",
        date: "2004-2007",
		activities: [
			"Software development with Delphi development",
			"Database development with Firebird, SQL Server and Oracle"
		]
    },                      
    {
        jobtitle: "Software Developer",
        where: "SCA",
        date: "2002-2004",
		activities: [
			"Software development with Delphi development",
			"Database development with Firebird and MySQL"
		]
    },                      
                      
];

const skills = [{
        name: "Unity",
        value: 90,
    },
    {
        name: "iOS Swift",
        value: 75,
    },
    {
        name: "ARKit",
        value: 90,
    },
    {
        name: "ARCore",
        value: 70,
    },
    {
        name: "Kotlin",
        value: 70,
    },
    {
        name: "Blender",
        value: 75,
    },	
	{
        name: "Playcanvas",
        value: 50,
    },
	{
        name: "8th wall",
        value: 70,
    },
    {
        name: "Babylon.js",
        value: 70,
    },
];

const services = [{
        title: "UI & UX Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Mobile Apps",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [{
        img: process.env.PUBLIC_URL + "/DiegoPoster.png",
		title: "Achroma",
        desctiption: "Achroma",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
		title: "Achroma",
        desctiption: "Achroma",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
		title: "Achroma",
        desctiption: "Achroma",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
		title: "Achroma",
        desctiption: "Achroma",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
		title: "Achroma",
        desctiption: "Achroma",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
		title: "Achroma",
        desctiption: "Achroma",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
		title: "Achroma",
        desctiption: "Achroma",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "name@domain.com",
    YOUR_FONE: "(555)123-4567",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vehicula eu nunc et sollicitudin. Cras pulvinar, nisi at imperdiet pharetra. ",
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com/diegomeire",
    linkedin: "https://www.linkedin.com/in/diego-meire-6a2a93218/",
    instagram: "https://instagram.com/diego.meire.arts",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};