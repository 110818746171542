import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

import {
  dataabout,
  meta,
  worktimeline,
  skills,
  services,
} from "../../content_option";

import {
  FaCalendar,
  FaBuilding,
} from "react-icons/fa";


export const About = () => {

    let model;
    if (window.innerWidth < 1000) {
      model = <model-viewer
		        style={{left: `0px`, width: `100%`, height: `80vh`}}
		        src={process.env.PUBLIC_URL + "/Diego.gltf"} 
		        ios-src= {process.env.PUBLIC_URL + "/Diego.usdz"} 
		        poster={process.env.PUBLIC_URL + "/DiegoPoster.png"}
		        alt="A 3D model of an astronaut"
		        ar
		  		autoplay
				seamless-poster shadow-intensity="1" 
				camera-controls
				/>;
    } else {
      model = <model-viewer
		        style={{left: `0px`, width: `100%`, height: `80vh`}}
		        src={process.env.PUBLIC_URL + "/Diego.gltf"} 
		        ios-src= {process.env.PUBLIC_URL + "/Diego.usdz"} 
		        poster={process.env.PUBLIC_URL + "/DiegoPoster.png"}
		        alt="A 3D model of an astronaut"
		        auto-rotate
		        ar
		  		autoplay
				seamless-poster shadow-intensity="1" 
				camera-controls/>;
    }
	
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          	<meta charSet="utf-8" />
          	<title> About | {meta.title}</title>
	    	<script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
          	<meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-8">About me</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            
				{model}
				
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <p style={{fontSize: "1.2rem"}}>{dataabout.aboutme}</p>
            </div>
          </Col>
        </Row>
		<Row className="sec_sp">
		  <Col lg="5">
		    <h3 className="color_sec py-4">Skills</h3>
		  </Col>
		  <Col lg="7">
		    {skills.map((data, i) => {
		      return (
		        <div key={i}>
		          <h3 className="progress-title">{data.name}</h3>
		          <div className="progress">
		            <div
		              className="progress-bar"
		              style={{
		                width: `${data.value}%`,
		              }}
		            >
		              <div className="progress-value">{data.value}%</div>
		            </div>
		          </div>
		        </div>
		      );
		    })}
		  </Col>
		</Row>
        <Row className=" sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Work Timeline</h3>
          </Col>
          <Col lg="7">

	             {worktimeline.map((data, i) => {
                  return (
					  
					  
                	  <div className="job_ py-4" key={i}>
					  	<h5 className="job__title" >{data.jobtitle} </h5>
					  	<table>
							<tbody>
							  	<tr>
					  				<td style={{paddingRight: "20px"}}><h6><FaBuilding style={{paddingRight: "5px"}} />{data.where}</h6><hr className="t_border my-2 ml-0 text-left" /></td>
					  				<td><h6><FaCalendar style={{paddingRight: "5px"}} />{data.date}<hr className="t_border my-2 ml-0 text-left" /></h6></td>
					  			</tr>
							</tbody>
					  		
					  	</table>
				  
            		  
				  		<ul style={{paddingTop: "10px", listStyle: "yes", listStyleType: "circle"}}>
					  	{data.activities.map((activities, i) => {
   					   return (
						  
							<li key={i}>
								<h6>{activities}</h6>					  
							</li>	  	
						);
					  })}
				
				  
				  </ul>
				  

                </div>

                     
                  );
                })}

          </Col>
        </Row>
        <Row className="mb-5 mt-3"/>

	  
	  </Container>
	  <section>
	  <div className="about-footer">
		  
	  	<p >Based on the beautiful portfolio of <a href="https://github.com/ubaimutl/react-portfolio" >ubaimutl</a></p>
	  </div>

	  </section>

    </HelmetProvider>


  );
};
